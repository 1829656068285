import React, {useEffect} from 'react'
// import styled from 'styled-components'

// const Container = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: stretch;
//   margin-bottom: 1rem;

//   @media ${({theme}) => theme.media.mobile} {
//     display: none;
//   }
// `

export const Adsense = (props) => {
  useEffect(() => {
    try {
      const adsbygoogle = window.adsbygoogle || []
      adsbygoogle.push({})
    } catch (e) {
      console.error(e)
    }
  }, [])

  return (
    // <Container {...props}>
      <ins
        className="adsbygoogle"
        style={{display: 'block'}}
        data-ad-layout="in-article"
        data-ad-format="fluid"
        data-ad-client="ca-pub-5260183074025747"
        data-ad-slot="4821855686"
      />
    // {/* </Container> */}
  )
}