import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../layout/index'
import config from "../../data/SiteConfig";
import SEO from "../components/SEO/SEO";
import { slugify } from '../utils/helpers'
import Commento from '../components/Commento/Commento'
import { MDXRenderer } from "gatsby-plugin-mdx"
import {Adsense} from "../components/Adsense"
// export const BlogPostTemplate = ({
//   id,
//   content,
//   tags,
//   title,
//   date,
//   author,
//   slug,
// }) => {

//   return (
    
//   )
// }

// BlogPostTemplate.propTypes = {
//   content: PropTypes.node.isRequired,
//   title: PropTypes.string,
// }

const BlogPost = ({ data }) => {
  const { mdx: post } = data
  function getDescription() {
    let description = post.yoast_meta.find( ({ name }) => name === 'description' );
    if(!description){
      description = post.yoast_meta.find( ({ property }) => property === 'og:description' );
    }
    return description.content
  }
  return (
    <Layout>
      <div className="container max-w-5xl mx-auto px-8 lg:px-0">
			    {/* <div className="flex flex-wrap justify-between pt-12"> */}
            {/* <Helmet title={`${post.title} | Blog`} /> */}
            <Helmet>
              <title>{`${post.frontmatter.title} | ${config.siteTitle}`}</title>
            </Helmet>
            <SEO postPath={post.fields.slug} postNode={post} postSEO />
            <section className="section pt-12">      
              <div className="container content leading-relaxed">
                <div className="columns">
                  <div className="column is-10 is-offset-1">
                    {post.frontmatter.tags && post.frontmatter.tags.length ? (
                      <div>
                        <nav className="text-gray-700 text-base my-2" aria-label="Breadcrumb">
                          <ol className="list-none p-0 inline-flex">
                            <li className="flex items-center">
                            <Link to={`/`} key="home" className='no-link text-decoration-none'>
                              HOME
                            </Link>
                              <svg className="fill-current w-3 h-3 mx-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"/></svg>
                            </li>
                            <li className="flex items-center">
                              <Link to={`/tags/${slugify(post.frontmatter.tags[0])}/`} key={post.frontmatter.tags[0]} className='no-link text-decoration-none'>
                                {post.frontmatter.tags[0].toUpperCase()}
                              </Link>
                            </li>
                          </ol>
                        </nav>
                      {/* {post.frontmatter.tags.map(tag => (
                        <Link to={`/tags/${slugify(tag)}/`} className='no-link mr-2' key={tag}>
                          <span className="bg-gray-300 hover:bg-gray-400 text-gray-800 text-sm py-2 px-4 rounded">{tag.toUpperCase()}</span>
                        </Link>
                      ))} */}
                      </div>
                        
                      ) : null}
                    <h1 className="title is-size-2 has-text-weight-bold is-bold-light leading-tight">
                      {post.frontmatter.title}
                    </h1>
                    <p className="text-sm text-gray-600 mt-0" style={{color: 'rgba(0,0,0,.6)'}}>By 
                    {/* <a href={(author && config.authors[author])?'/author/'+config.authors[author].slug:'/author/parthpatel'}> */}
                      &nbsp;{(post.frontmatter.author && config.authors[post.frontmatter.author])?config.authors[post.frontmatter.author].name:'Parth Patel'}&nbsp;
                      {/* </a> */}
                        on {post.frontmatter.date}</p>
                    {/* <div className="py-4" dangerouslySetInnerHTML={{ __html: content }} /> */}
                    <Adsense/>
                    <MDXRenderer className="py-4">{post.body}</MDXRenderer>
                    {/* <Commento id={post.id} /> */}
                  </div>
                </div>
              </div>
            </section>
          {/* </div> */}
      </div>
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    mdx: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
query BlogPostBySlug($slug: String!) {
  mdx(fields: { slug: { eq: $slug } }) {
    id
    body
    timeToRead
    excerpt
    frontmatter {
      title
      coverImage
      author
      date(formatString: "MMM DD, YYYY")
      tags
    }
    fields {
      slug
    }
  }
}
`
